
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93ZSUQToF1pWMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93gsAqtxJfRxMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/cta-banners/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93iD8bzPri0cMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/eeat-infos/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93enaHCGs8LAMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/simple-cards/[...slug].vue?macro=true";
import { default as _91slug_93649PXHm5ivMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/sticky-zip-cta/[slug].vue?macro=true";
import { default as _91slug_930LpMzGRrR2Meta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/vertical-cta-cards/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93txweZ2X5GSMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/navigation/[...slug].vue?macro=true";
import { default as indexBkSy9fsDpaMeta } from "/home/runner/work/es-cms-pages/es-cms-pages/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ZSUQToF1pWMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/pages/[...slug].vue")
  },
  {
    name: "global-components-cta-banners-slug",
    path: "/global/components/cta-banners/:slug(.*)*",
    meta: _91_46_46_46slug_93gsAqtxJfRxMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/cta-banners/[...slug].vue")
  },
  {
    name: "global-components-eeat-infos-slug",
    path: "/global/components/eeat-infos/:slug(.*)*",
    meta: _91_46_46_46slug_93iD8bzPri0cMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/eeat-infos/[...slug].vue")
  },
  {
    name: "global-components-simple-cards-slug",
    path: "/global/components/simple-cards/:slug(.*)*",
    meta: _91_46_46_46slug_93enaHCGs8LAMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/simple-cards/[...slug].vue")
  },
  {
    name: "global-components-sticky-zip-cta-slug",
    path: "/global/components/sticky-zip-cta/:slug()",
    meta: _91slug_93649PXHm5ivMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/sticky-zip-cta/[slug].vue")
  },
  {
    name: "global-components-vertical-cta-cards-slug",
    path: "/global/components/vertical-cta-cards/:slug()",
    meta: _91slug_930LpMzGRrR2Meta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/components/vertical-cta-cards/[slug].vue")
  },
  {
    name: "global-navigation-slug",
    path: "/global/navigation/:slug(.*)*",
    meta: _91_46_46_46slug_93txweZ2X5GSMeta || {},
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/pages/global/navigation/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/es-cms-pages/es-cms-pages/pages/index.vue")
  }
]